app = {
    isValidSelector: function (selector) {
        if (typeof (selector) !== 'string') {
            return false;
        }

        try {
            var $e = $(selector);
            return $e.length > 0;
        } catch (error) {
            return false;
        }
    },

    monitorAuthStatus: function (redirectUrl, delay) {
        window.setTimeout(function () {
            $.ajax({
                url: '/ping',
                success: function (data) {
                    if (!data.logged_in) {
                        window.location = redirectUrl;
                    }
                }
            });
        }, delay);
    },

    initPickColor: function () {
        $('.pick-class-label').click(function () {
            var new_class = $(this).attr('new-class');
            var old_class = $('#display-buttons').attr('data-class');
            var display_div = $('#display-buttons');
            if (display_div.length) {
                var display_buttons = display_div.find('.btn');
                display_buttons.removeClass(old_class);
                display_buttons.addClass(new_class);
                display_div.attr('data-class', new_class);
            }
        });
    },
    initFieldsCollectionWidget: function () {
        $('.collection-widget-list').each(function (ind, item) {
            const $widget = $(item),
                addButtonSelector = '.add-collection-widget',
                indexDataKey = 'last-item-index',
                noDeleteButton = $widget.data('no-delete-button'),
                noWrapItem = $widget.data('no-wrap');

            const minItems = parseInt($widget.data('min-items')) || 0;
            const maxItems = parseInt($widget.data('max-items'));

            const countItems = function () {
                return $widget.find('.field-list-item').length;
            }

            const nextIndex = function () {
                const index = $widget.data(indexDataKey);
                setIndex(index + 1);

                return index;
            }

            const getIndex = function () {
                return $widget.data(indexDataKey);
            }

            const setIndex = function (index) {
                $widget.data(indexDataKey, index);
            }

            const toggleAddButtonVisibility = function () {
                if (maxItems > 0 && countItems() >= maxItems) {
                    $widget.find(addButtonSelector).hide();
                } else {
                    $widget.find(addButtonSelector).show();
                }
            }

            const adjustActions = function () {
                for (let i = 0; i < minItems; i++) {
                    $widget.find('.field-list-item:eq(' + i + ') .delete-item').remove()
                }
                toggleAddButtonVisibility();
            }

            setIndex(countItems());

            $widget.on('click', addButtonSelector, function (e) {
                e.preventDefault();
                e.stopPropagation();

                if (maxItems > 0 && countItems() >= maxItems) {
                    return;
                }

                const itemHTLM = $widget.data('prototype').replace(/__name__/g, nextIndex()),
                    headerHTML = $widget.data('prototype-header') || '',
                    deleteIcon = $widget.data('delete-glyphicon') || 'trash',
                    list = $widget.find('.field-list-items');

                let deleteButtonHtml = '';

                if (!noDeleteButton) {
                    if ($widget.data(indexDataKey) > minItems && !$(headerHTML).find('.delete-item').length) {
                        deleteButtonHtml = '<span class="delete-item"><i class="glyphicon glyphicon-' + deleteIcon + '"></i></span>';
                    } else {
                        $(headerHTML).find('.delete-item').remove();
                    }
                }

                let newItem;
                if (noWrapItem && headerHTML.length === 0 && deleteButtonHtml.length === 0) {
                    newItem = $(itemHTLM);
                } else {
                    newItem = $('<div class="field-list-item"></div>').html(headerHTML + itemHTLM + deleteButtonHtml);
                }

                if (list.length > 0) {
                    list.append(newItem);
                } else {
                    newItem.insertBefore($(this)); // BC
                }

                $widget.find('.field-list-item-index').each(function (index) {
                    $(this).html(index + 1);
                });

                adjustActions();
                app.initInputPlugins();
                app.initMoneyFields(list);
            });

            $widget.on('click', '.delete-item', function (e) {
                e.preventDefault();
                $(this).closest('.field-list-item').remove();
                toggleAddButtonVisibility();
            });

            for (let i = getIndex(); i < minItems; i++) {
                $(addButtonSelector).trigger("click");
            }
            adjustActions();
        });
    },

    initCopyTextButton: function () {
        $(document).on('click', '.copy', function () {
            if (!navigator.clipboard) {
                return;
            }
            navigator.clipboard.writeText($(this).text());

            var btn = $(this);
            btn.addClass('copied').attr('title', 'Copied!');
            window.setTimeout(function () {
                btn.removeClass('copied')
                    .attr('title', null);
            }, 1500);
        });
    },

    initInputPlugins: function () {
        app.initCheckboxes();
        app.initDropdowns();
        app.initAutocompleter();
        app.initDateRangePicker();
        app.initFilesUploader();
        app.initTooltip();
    },

    initMomentJs: function (timezone, locale) {
        if (timezone) {
            moment.tz.setDefault(timezone);
        }

        // register plugin function
        moment.fn.getTimezone = function () {
            return moment.defaultZone ? moment.defaultZone.name : moment.tz.guess();
        };

        if (locale) {
            moment.locale(locale);
        }
    },

    initResponsiveTabNavs: function () {
        $('.nav.nav-tabs').each(function () {
            var $nav = $(this);

            $nav.addClass('responsive-tabs');
            $nav.append($('<span class="responsive-tabs-arrow glyphicon glyphicon-triangle-bottom"></span>'));
            $nav.append($('<span class="responsive-tabs-arrow glyphicon glyphicon-triangle-top"></span>'));

            $nav.on('click', 'li.active > a, span.glyphicon', function () {
                $nav.toggleClass('open');
            });

            $nav.on('click', 'li:not(.active) > a', function () {
                $nav.removeClass('open');
            });
        });
    },

    formatAmount: function (amount, currency, locale, minorDigits) {
        var formattedValue = '-';

        if (amount === '') {
            return formattedValue;
        }

        var options = {
            style: 'currency',
            currency: currency,
        };

        if ($.isNumeric(minorDigits) && minorDigits >= 0) {
            options.minimumFractionDigits = minorDigits;
            options.maximumFractionDigits = minorDigits;
        }

        return this.formatNumber(amount, locale || 'en-US', options);
    },

    formatNumber: function (number, locale, options) {
        if (number === '') {
            return number;
        }

        var formatter = new Intl.NumberFormat(locale || this.getBrowserLocale(), options),
            fractionDigits = formatter.resolvedOptions().minimumFractionDigits;

        if (fractionDigits) {
            number = number / Math.pow(10, fractionDigits);
        }

        return formatter.format(number);
    },

    parseAmount: function (value, locale, minorDigits) {
        var normalized = this.normalizeNumber(value, locale),
            number = parseInt((normalized * Math.pow(10, minorDigits)).toFixed());

        if (isNaN(number)) {
            return 0;
        }

        return number;
    },

    normalizeNumber: function (value, locale) {
        locale = locale || this.getBrowserLocale();

        var example = Intl.NumberFormat(locale).format('1.1'),
            decimalSeparator = example.charAt(1),
            cleanPattern = new RegExp(`[^-+0-9${decimalSeparator}]`, 'g'),
            cleaned = value.replace(cleanPattern, '');

        return cleaned.replace(decimalSeparator, '.');
    },

    getBrowserLocale: function () {
        var locale = navigator.languages !== undefined ? navigator.languages[0] : navigator.language;
        return locale || 'en-US';
    },

    initMoneyFields: function ($container, locale) {
        function extractCurrencyMinorDigits(provider) {
            if (app.isValidSelector(provider)) {
                provider = $(provider);
            }

            if (!(provider instanceof jQuery)) {
                return null;
            }

            var currencyMinorDigits = parseInt(provider.data('minor-digits'));

            if (isNaN(currencyMinorDigits) && provider.prop('tagName') === 'SELECT') {
                currencyMinorDigits = parseInt(provider.children('option:selected').data('minor-digits'));
            }

            return currencyMinorDigits;
        }

        function extractCurrency(provider) {
            return app.isValidSelector(provider) ? $(provider).val() : provider;
        }

        $container.find('input[type="number"][data-currency].money-preview').each(function () {
            var $input = $(this),
                $preview = $('<input type="text">'),
                currencyProvider = $input.data('currency');

            $preview.attr('style', $input.attr('style'));
            $preview.attr('class', $input.attr('class'));
            $preview.removeClass('money-preview');
            $preview.focus(function () {
                $preview.hide();
                $input.show();
            });

            var update = function () {
                var amount = $input.val(),
                    currency = extractCurrency(currencyProvider),
                    currencyMinorDigits = extractCurrencyMinorDigits(currencyProvider);


                $input.hide();
                $preview.val(app.formatAmount(amount, currency, locale, currencyMinorDigits)).show();
            };

            $input.blur(update);
            if (app.isValidSelector(currencyProvider)) {
                $(currencyProvider).on('change', update);
            }

            $input.after($preview);
            $input.blur();
        });

        $container.find('input[type="number"][data-currency][data-money-preview]').each(function () {
            var $input = $(this),
                $preview = $container.find($input.data('money-preview')),
                currencyProvider = $input.data('currency');

            var update = function () {
                var amount = $input.val(),
                    currency = extractCurrency(currencyProvider),
                    currencyMinorDigits = extractCurrencyMinorDigits(currencyProvider);

                $preview.html(app.formatAmount(amount, currency, locale, currencyMinorDigits));
            };

            $input.change(update);
            $input.keyup(update);
            $input.blur(update);
            if (app.isValidSelector(currencyProvider)) {
                $(currencyProvider).on('change', update);
            }

            update();
        });

        $('.money-preview-container').each(function () {
            var $container = $(this),
                $input = $container.find('input[type="hidden"]'),
                $preview = $container.find('input[type="text"]');

            $preview.on('focus', function () {
                $preview.val($input.val());
                $preview.attr('type', 'number');
            });

            $preview.on('blur', function () {
                var currencyProvider = $input.data('currency'),
                    currency = extractCurrency(currencyProvider),
                    currencyMinorDigits = extractCurrencyMinorDigits(currencyProvider),
                    amount = parseInt($preview.val().replace(/[^-\d]/g, ''));

                if (isNaN(amount)) {
                    amount = 0;
                }

                $input.val(amount);
                $preview.attr('type', 'text');
                $preview.val(app.formatAmount(amount, currency, locale, currencyMinorDigits));
            });
        });

        $container.find('.money-widget').each(function () {
            var widget = $(this),
                amountInput = widget.find('input[data-money-amount]'),
                previewInput = widget.find('input[data-money-view]'),
                preview = widget.find('.preview'),
                currencyInput = widget.find('select[data-money-currency]'),
                currencyMinorDigits = parseInt(currencyInput.children('option:selected').data('minor-digits')) || 0;

            preview.html(app.formatAmount(amountInput.val(), currencyInput.val(), locale, currencyMinorDigits));
            previewInput.val(app.formatNumber(amountInput.val() || 0, locale, {
                minimumFractionDigits: currencyMinorDigits,
                maximumFractionDigits: currencyMinorDigits
            }));

            var update = function () {
                var amount = app.parseAmount(previewInput.val(), locale, currencyMinorDigits);
                var formattedAmount = app.formatAmount(amount, currencyInput.val(), locale, currencyMinorDigits);
                amountInput.val(amount);
                preview.html(formattedAmount);
            };

            previewInput.change(update);
            previewInput.keyup(update);
            previewInput.blur(update);
        });
    },

    formatDates: function (container) {
        container = container || $(document);
        $(container).find('.relative-date').each(function () {
            var dateString = $.trim($(this).text()),
                date = $(this).hasClass('timestamp-date') ? moment.unix(dateString) : moment(dateString),
                now = moment(),
                text = date.format('lll');

            if (now.diff(date, 'days') < 3) {
                text = date.fromNow();
            }

            $(this).attr('title', date.format('LLLL'));
            $(this).text(text);
            $(this).removeClass('relative-date');
        });

        $(container).find('.absolute-date').each(function () {
            var dateString = $.trim($(this).text()),
                date = $(this).hasClass('timestamp-date') ? moment.unix(dateString) : moment(dateString),
                dateFormat = $(this).data('dateFormat') || 'llll',
                title = $(this).attr('title');

            $(this).attr('title', (title || '%date%').replace('%date%', date.format(dateFormat)));
            $(this).text(date.format(dateFormat));
            $(this).removeClass('absolute-date');
        });
    },

    showSuccess: function (title, message) {
        this.showNotification(title, message, 'success', 'fa fa-check', 5000);
    },

    showError: function (title, message) {
        title = typeof title === 'undefined' ? 'Oops, something unexpected happened' : title;
        message = typeof message === 'undefined'
            ? 'Sorry, but something went wrong. Please try again or get in contact with us'
            : message;

        this.showNotification(title, message, 'danger', 'fa fa-exclamation-triangle', 60000);
    },

    showNotification: function (title, message, type, icon, delay) {
        // type: '', 'info', 'success', 'warning', 'danger'

        $.notify(
            {
                icon: icon,
                title: title,
                message: message,
                url: null,
                target: null,
            },
            {
                type: type,
                delay: delay ? delay : 4000,
                z_index: 10000,
                placement: {
                    from: 'top',
                    align: 'center'
                },
                template: '<div data-notify="container" class="col-xs-11 col-sm-6 alert alert-{0}" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss">&times;</button><span data-notify="icon"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
            }
        );
    },

    callAjax: function (ajaxOptions, loadingIcon) {
        var loaderOptions = {};
        if (loadingIcon) {
            loaderOptions.fontawesome = 'fa fa-spin fa-3x fa-fw fa-' + loadingIcon;
        }

        if (ajaxOptions.data instanceof FormData) {
            ajaxOptions.type = 'POST';
            ajaxOptions.cache = false;
            ajaxOptions.processData = false;
            ajaxOptions.contentType = false;
        }

        var onSuccess = ajaxOptions.success || function () {
        };
        ajaxOptions.success = function (data) {
            if (data.error) {
                app.showMessageForApiError(data);
                $.LoadingOverlay('hide');
            } else {
                onSuccess(data);
            }
        };

        var onError = ajaxOptions.error || function () {
        };
        ajaxOptions.error = [
            function () {
                $.LoadingOverlay('hide');
            },
            onError
        ];

        $.LoadingOverlay('show', loaderOptions);

        return $.ajax(ajaxOptions);
    },

    callApi: function (method, params, onSuccess, loadingIcon) {
        var formData;
        if (params instanceof FormData) {
            formData = params;
        } else {
            formData = new FormData();
            for (var key in params) {
                if (params.hasOwnProperty(key)) {
                    formData.append(key, params[key]);
                }
            }
        }

        if (!formData.has('rpc_method')) {
            formData.append('rpc_method', method);
        }

        var loaderOptions = {};
        if (loadingIcon) {
            loaderOptions.fontawesome = 'fa fa-spin fa-3x fa-fw fa-' + loadingIcon;
        }

        $.LoadingOverlay('show', loaderOptions);

        $.ajax({
            url: '/apiproxy',
            data: formData,
            type: 'POST',
            cache: false,
            processData: false,
            contentType: false,
            success: function (data) {
                if (data.error) {
                    $.LoadingOverlay('hide');
                    app.showMessageForApiError(data);
                } else {
                    onSuccess(data, function () {
                        $.LoadingOverlay('hide');
                    });
                }
            },
            error: function () {
                $.LoadingOverlay('hide');
                app.showError('Error', 'API request failed');
            }
        });
    },

    showMessageForApiError: function (rsp) {
        var details = '';
        if (rsp.details && !$.isEmptyObject(rsp.details.errors || [])) {
            var $ul = $('<ul>');
            for (var field in rsp.details.errors) {
                var prefix = '';
                if (!$.isNumeric(field)) {
                    prefix = '<li><b>' + field + '</b>: ';
                }
                $ul.append(prefix + rsp.details.errors[field] + '</li>');
            }
            details = $('<div><p style="margin-top: 20px;">Details</p></div>').append($ul).html();
        }

        app.showError(
            rsp.error,
            details
        );
    },

    textareaTemplates: {
        register: function () {
            $('[data-textarea-templates]').each(function () {
                var $this = $(this);
                app.textareaTemplates.registerElement($this, $this.data('textareaTemplates'), $this.data('language'));
            });
        },
        registerElement: function ($e, id, language) {
            var $root = $e.parent('.form-group');
            $root.find('.textarea-templates').remove();

            app.textareaTemplates.retrieve(id, language, function (templates) {
                if (!templates.length) {
                    return;
                }

                var $selector = $('<select class="form-control">');
                $selector.change(function () {
                    if ($(this).val() === '') {
                        return;
                    }

                    var val = $e.val();
                    val = val === '' ? $(this).val() : val + "\n" + $(this).val();
                    $e.val(val);
                });
                $selector.append('<option value="">Templates</option>');
                for (var i = 0, c = templates.length; i < c; i++) {
                    var $option = $('<option>');
                    $option.val(templates[i].content).text(templates[i].label);
                    $selector.append($option);
                }
                $root.prepend($('<div class="textarea-templates form-group pull-right">').append($selector));
            });
        },
        retrieve: function (id, language, cb) {
            app.callAjax({
                url: '/textarea-templates/' + id + '?language=' + language,
                success: function (data) {
                    app.hideLoadingOverlay();
                    cb(data.templates);
                }
            });
        },
        store: function (id, templates) {
            app.callAjax({
                type: 'post',
                url: '/textarea-templates/' + id,
                data: {
                    templates: templates
                },
                success: function () {
                    app.hideLoadingOverlay();
                }
            });
        }
    },

    reloadPage: function () {
        $.LoadingOverlay('show');
        window.location.reload();
    },

    hideLoadingOverlay: function () {
        $.LoadingOverlay('hide');
    },

    showLoadingOverlay: function () {
        $.LoadingOverlay('show');
    },

    initEnvBanner: function () {
        $('#env_banner').click(function () {
            $(this).hide();
        });
    },

    initSidebar: function () {
        var $wrapper = $('.wrapper'),
            $tooltips = $('.sidebar-wrapper [data-toggle="tooltip"]'),
            $collapse = $('.sidebar-toggle-collapse'),
            $expand = $('.sidebar-toggle-expand');

        $collapse.on('click', function () {
            $wrapper.addClass('sidebar-sm');
            $tooltips.tooltip({container: 'body'});
            storage.setItem('is-sidebar-collapsed', '1');
        });
        $expand.on('click', function () {
            $wrapper.removeClass('sidebar-sm');
            $tooltips.tooltip('destroy');
            storage.setItem('is-sidebar-collapsed', '0');
        });

        if (storage.getItem('is-sidebar-collapsed') === '1') {
            $collapse.trigger('click');
        } else {
            $expand.trigger('click');
        }
    },

    openActiveTab: function () {
        var url = document.location.toString();
        if (url.match('#')) {
            $('.nav-tabs a[href="#' + url.split('#')[1] + '"]').tab('show');
        }

        $('.nav-tabs a').on('shown.bs.tab', function (e) {
            if (history.pushState) {
                history.pushState(null, '', e.target.hash);
            } else {
                window.location.hash = e.target.hash;
            }
        });
    },

    applySidebarStyles: function (backgroundColor, fontColor, webLogoUrl) {
        $('.theme-background').attr('style', 'background-color: ' + backgroundColor + ' !important;');
        $('.theme-color a').add('.theme-color .btn').attr('style', 'color: ' + fontColor + ' !important;');
        $('.theme-color .btn .caret').attr('style', 'border-top-color: ' + fontColor + ' !important;');
        if (!webLogoUrl) {
            webLogoUrl = $('.theme-web-logo').data('default');
        }
        $('.theme-web-logo').attr('style', 'background-image: url("' + webLogoUrl + '") !important;');
    },

    initCheckboxes: function ($container) {
        $container = typeof $container !== "undefined" ? $container : $("body");
        $container.find('input[data-toggle="checkbox"]').checkbox();
    },

    initDropdowns: function () {
        $('select.selectpicker').selectpicker();
    },

    initDateRangePicker: function () {
        var ranges = {
            'Today': [moment().startOf('day'), moment().endOf('day')],
            'Yesterday': [moment().subtract(1, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')],
            'Last 7 Days': [moment().subtract(6, 'days').startOf('day'), moment().endOf('day')],
            'Last 30 Days': [moment().subtract(29, 'days').startOf('day'), moment().endOf('day')],
            'This Month': [moment().startOf('month'), moment().endOf('day')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        };

        $('.datetimerange-widget').each(function () {
            var picker = $(this).find('.datetimerange-widget-view');
            var valueField = $(this).find('.datetimerange-widget-value');
            var tz = $(this).find('input[name*="[timezone]"]');

            var viewFormat = picker.data('format');
            var modelFormat = picker.data('model-format');

            // render date picker into modal window if from is inside the modal, otherwise use fallback to `body` tag
            var parentEl = picker.closest('.modal');
            if (picker.closest('.modal').length === 0) {
                parentEl = 'body';
            }

            var timezone = moment().getTimezone();
            if (!tz.val()) {
                tz.val(timezone);
            }

            var options = {
                parentEl: parentEl,
                autoUpdateInput: true,
                timePicker: parseInt(picker.data('time')) > 0,
                timePicker24Hour: parseInt(picker.data('time-picker-24hour')) > 0,
                timePickerIncrement: parseInt(picker.data('time-picker-increment')) || 15,
                singleDatePicker: parseInt(picker.data('single-date')) > 0,
                showDropdowns: parseInt(picker.data('show-dropdowns')) > 0,
                minYear: picker.data('min-year'),
                maxYear: picker.data('min-year'),
                minDate: picker.data('min-date') ? moment(picker.data('min-date')).startOf('day') : undefined,
                maxDate: picker.data('max-date') ? moment(picker.data('max-date')).endOf('day') : undefined,
                ranges: picker.data('quick-ranges') > 0 ? ranges : false,
                maxSpan: picker.data('no-limit-span') ? null : {
                    days: parseInt(picker.data('max-span-days')) || 365
                },
                locale: {
                    format: viewFormat,
                    cancelLabel: 'Clear'
                },
            };

            var dates = valueField.val().split(' - ');
            var range = {
                start: dates[0] || null,
                end: dates[1] || null
            };

            if (range.start) {
                options.startDate = moment(range.start).format(viewFormat);
            }

            if (!options.singleDatePicker && range.end) {
                options.endDate = moment(range.end).format(viewFormat);
            }

            picker.daterangepicker(options)
                .on('show.daterangepicker', function (e, pk) {
                    if (pk.singleDatePicker) {
                        return; // timezone in unnecessary if there is no time
                    }

                    var container = $(pk.container);
                    if (container.find('.daterange-header').length === 0) {
                        $('<div class="daterange-header text-muted"></div>')
                            .html('Timezone: ' + timezone)
                            .prependTo(container);
                    }
                })
                .on('apply.daterangepicker', function (ev, datepicker) {
                    var viewValue = datepicker.startDate.format(viewFormat);
                    var modelValue = datepicker.startDate.format(modelFormat);

                    if (!picker.singleDatePicker) {
                        viewValue += (' - ' + datepicker.endDate.format(viewFormat));
                        modelValue += (' - ' + datepicker.endDate.format(modelFormat));
                    }

                    picker.val(viewValue);
                    valueField.val(modelValue);
                })
                .on('cancel.daterangepicker', function () {
                    picker.val('');
                    valueField.val('');
                }).on('hide.daterangepicker', function () {
                if (valueField.val() === '') {
                    picker.val('');
                }
            });

            if (range.start === null && range.end === null) {
                picker.trigger('cancel.daterangepicker');
            }
        });
    },

    initTooltip: function () {
        $(document).find('[data-toggle="tooltip"]').tooltip();
    },

    initFilesUploader: function () {
        function displayError(widget, message) {
            widget.find('.uploaded-file-error').show().find('.message').text(message);
        }

        $('.files-uploader-widget').each(function (ind, item) {

            var widgetName = 'file-uploader-widget',
                element = $(item),
                formControl = $('#' + element.data('form-control')),
                prototypeName = element.data('prototype-name') || '__name__';

            if (element.data('widget') === widgetName) {
                return;
            }

            element.find('.file-upload-info-tooltip').tooltip();
            element.data('widget', widgetName);
            element.data('files-index', element.find('.file-row').length - 1);

            element.find('.add-file').fileupload({
                dataType: 'json',
                url: element.data('add-url'),
                add: function (e, data) {
                    var index = element.data('files-index'),
                        files = data.files,
                        maxFileSize = parseFloat(element.data('max-file-size')),
                        widget = element
                            .find('.file-row-template')
                            .clone()
                            .removeClass('hidden file-row-template')
                            .attr('id', '_widget_' + index);

                    $(element.data('prototype')
                        .replace(new RegExp(prototypeName, 'g'), index))
                        .addClass('attachment')
                        .appendTo(widget);

                    widget.insertBefore($(this).parent('.file-row'));
                    widget.find('.progress').hide();
                    element.data('files-index', index + 1);

                    data.context = widget;

                    if (files.length !== 1) {
                        return;
                    }

                    if (files[0].size > maxFileSize) {
                        return displayError(widget, 'You can upload files up to 20Mb only');
                    }

                    widget.find('.progress').show();
                    formControl.prop('disabled', true);
                    data.submit();
                },
                progress: function (e, data) {
                    var progress = parseInt(data.loaded / data.total * 100, 10);
                    data.context.find('.progress-bar')
                        .attr('aria-valuenow', progress)
                        .css('width', progress + '%')
                        .text(progress + '%');
                },
                done: function (e, data) {
                    var widget = data.context;

                    if (data.result.error !== null) {
                        return displayError(widget, data.result.error || 'Error');
                    }

                    widget.find('input[type="file"]').remove();
                    widget.find('.attachment input[name*="[url]"]').val(data.result.file_url);
                    widget.find('.attachment input[name*="[name]"]').val(data.result.file_name);
                    widget.find('.uploaded-file').show().find('.filename').html(data.result.file_name);
                },
                fail: function (e, data) {
                    var response = data.jqXHR.responseJSON,
                        message = element.data('default-error-message');

                    if (response && response.error) {
                        message = response.error;
                    }

                    displayError(data.context, message);
                },
                always: function (e, data) {
                    data.context.find('.progress').hide();
                    formControl.prop('disabled', false);
                }
            });

            element.on('click', '.file-row .delete-file', function (e) {
                e.preventDefault();
                e.stopPropagation();

                var button = $(this);
                if (button.hasClass('disabled')) {
                    return;
                }

                button.addClass('disabled');
                var widget = $(this).closest('.file-row'),
                    deleteUrl = element.data('del-url'),
                    fileUrl = widget.find('.attachment input[name*="[url]"]').val();

                if (fileUrl.length === 0 || deleteUrl === '') {
                    return widget.remove();
                }

                $.post(deleteUrl, {file_url: fileUrl})
                    .done(function () {
                        widget.remove();
                    })
                    .fail(function () {
                        displayError(widget, element.data('default-error-message'));
                    })
                    .always(function () {
                        button.removeClass('disabled');
                    });
            });
        });
    },

    confirmSubmit: function (formName) {
        var $form = $('form[name="' + formName + '"]');
        if ($form.length === 0) {
            return;
        }

        $form.data('_original_form_', $form.serialize());
        $form.on('submit', function () {
            $form.data('_original_form_', null);
        });

        $(window).bind('beforeunload', function () {
            // custom messages in dialogs are deprecated since chrome-51 (see changelog)
            var originalForm = $form.data('_original_form_');
            if (originalForm && $form.serialize() !== originalForm) {
                return false;
            }
        });
    },

    initAutocompleter: function () {
        $(document).ready(function () {
            $('[data-autocompleter="on"]').each(function (ind, input) {
                var url = $(input).data('autocompleter-url'),
                    options = $(input).data('autocompleter-options'),
                    showOnFocus = $(input).data('autocompleter-show-on-focus') === 'on',
                    fetchFn = function (text, update) {
                        return update(false);
                    };

                if (url) {
                    var xhr = null;
                    fetchFn = function (text, update) {
                        if (xhr !== null) {
                            xhr.abort();
                        }

                        xhr = $.ajax({
                            url: url,
                            method: 'get',
                            data: {
                                search_string: text
                            }
                        }).success(function (resp) {
                            update(resp);
                        }).fail(function () {
                            update(false);
                        }).always(function () {
                            xhr = null;
                        });
                    }
                } else if (options) {
                    fetchFn = function (text, update) {
                        return update(options);
                    };
                }

                autocomplete({
                    input: input,
                    minLength: 2,
                    debounceWaitMs: 800,
                    emptyMsg: 'Not Found',
                    showOnFocus: showOnFocus,
                    fetch: fetchFn,
                    onSelect: function (item) {
                        $(input).val(item.value);
                    }
                });
            })
        });
    },

    initPopoverSingleton: function (selector, options) {
        var isShown = function (element) {
                return $(element).attr('aria-describedby');
            },
            hideAll = function () {
                $(selector).each(function () {
                    if (isShown(this)) {
                        $(this).popover('hide');
                    }
                });
            }

        options.trigger = 'manual'; // works only with this option

        $(selector).popover(options).on('click', function () {
            var toShow = !isShown(this);
            hideAll();
            if (toShow) {
                $(this).popover('show');
            }
        });

        $('body').on('click', function (e) {
            var $target = $(e.target);
            if ($target.parents('.popover').length) {
                return;
            }
            if ($(selector).children().addBack().is($target)) {
                return;
            }
            hideAll();
        });
    },

    initPhoneNumbers: function (container) {
        container = container || $(document);
        $(container).find('input[data-phone-number]').each(function () {
            var $input = $(this),
                callback = function () {
                    var val = $input.val().replace(/[^0-9]/g, '');
                    $input.val(val === '' ? val : '+' + val);
                };

            $input.change(callback).keyup(callback).blur(callback);

            callback();
        });
    }
};

storage = {
    setItem: function (item, data) {
        if (typeof (Storage) !== "undefined") {
            localStorage.setItem(item, data);
        }
    },
    getItem: function (item) {
        if (typeof (Storage) !== "undefined") {
            return localStorage.getItem(item);
        }
    }
};
